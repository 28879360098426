.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.modal__content {
  width: 90%;
  max-height: calc(75vh - 4rem);
  overflow-y: scroll;
  margin: 2rem auto;
}

.modal {
  position: fixed;
  top: 12vh;
  min-height: 10rem;
  max-height: 75vh;
  left: calc(50% - 40%);
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 100;
  border-radius: 7px;
}

.modal__close-button {
  position: absolute;
  top: 0.5em;
  right: 1em;
  display: block;
}
