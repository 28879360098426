.search-bar__container {
  width: 60%;
  height: 2em;
  margin: auto;
}

.search-bar__content {
  width: 100%;
  height: 2em;
  line-height: 2em;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: pre;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.search-bar__content::-webkit-scrollbar {
  display: none;
}

.search-bar__input {
  width: 80%;
  padding-right: 100px;
}

.search-bar__input::-ms-clear {
  display: none;
}

.search-bar__tag {
  border-radius: 0.5em;
  background-color: blue;
}

.search-bar__elm {
  margin: 0;
  margin-right: 0.5em;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.search-bar__suggestions {
  position: absolute;
}
